.mySwiper {
  width: 100%;
  padding: 20px 0;
}

.swiper-slide {
  height: 400px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.swiper-slide-img {
  width: auto;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transition: transform 0.3s ease;
}

@media (max-width: 640px) {
  .swiper-slide {
    height: 600px;
  }
  .swiper-slide-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.5rem;
  }
}

.swiper-slide:hover .swiper-slide-img {
  transform: scale(1.02);
}

.swiper-pagination {
  margin-top: 30px !important;
  position: relative !important;
}

.swiper-pagination-bullet {
  background-color: #7CA834 !important;
  width: 10px !important;
  height: 10px !important;
  margin: 0 6px !important;
}

.swiper-pagination-bullet-active {
  background-color: #7CA834 !important;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal.open {
  opacity: 1;
  pointer-events: auto;
}

.modal__image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 0.5rem;
  transform: scale(0.95);
  transition: transform 0.3s ease;
}

.modal.open .modal__image {
  transform: scale(1);
} 